import $ from 'jquery';

$(function() {
	$('#contact-input .js-select').each(function() {
		let x, i, j, l, ll, selElmnt, a, b, c, z, za;
		x = this;

		selElmnt = x.getElementsByTagName('select')[0];
		ll = selElmnt.length;
		a = document.createElement('DIV');
		z = document.createElement('DIV');
		a.setAttribute('class', 'select-selected');
		z.setAttribute('class', 'select-selected-inner');
		a.appendChild(z);
		z.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
		x.appendChild(a);
		b = document.createElement('DIV');
		b.setAttribute('class', 'select-items select-hide');
		for (j = 1; j < ll; j++) {
			c = document.createElement('DIV');
			za = document.createElement('DIV');
			za.setAttribute('class', 'select-click-inner');
			za.innerHTML = selElmnt.options[j].innerHTML;
			c.appendChild(za);
			c.addEventListener('click', function(e) {
				let y, i, k, s, h, sl, yl, tx;
				s = this.parentNode.parentNode.getElementsByTagName('select')[0];
				sl = s.length;
				h = this.parentNode.previousSibling;
				tx = this.querySelector('.select-click-inner').innerHTML;

				for (i = 0; i < sl; i++) {
					if (s.options[i].innerHTML === tx) {
						s.selectedIndex = i;
						h.innerHTML = tx;
						y = this.parentNode.getElementsByClassName('same-as-selected');
						yl = y.length;
						for (k = 0; k < yl; k++) {
							y[k].removeAttribute('class');
						}
						this.setAttribute('class', 'same-as-selected');
						break;
					}
				}

				h.click();
			});
			b.appendChild(c);
		}
		x.appendChild(b);

		a.addEventListener('click', function(e) {
			e.stopPropagation();
			closeAllSelect(this);
			this.nextSibling.classList.toggle('select-hide');
			this.classList.toggle('select-arrow-active');
		});

		function closeAllSelect(elmnt) {
			let x, y, i, xl, yl, arrNo = [];
			x = document.getElementsByClassName('select-items');
			y = document.getElementsByClassName('select-selected');
			xl = x.length;
			yl = y.length;

			for (i = 0; i < yl; i++) {
				if (elmnt === y[i]) {
					arrNo.push(i);
				} else {
					y[i].classList.remove('select-arrow-active');
				}
			}

			for (i = 0; i < xl; i++) {
				if (arrNo.indexOf(i)) {
					x[i].classList.add('select-hide');
				}
			}
		}

		document.addEventListener('click', closeAllSelect);
	});
});

$(function() {
	let pageInput = $('#contact-input');
	let btns = $('.js-btnsubmit input');
	if (pageInput.length && btns.length) {
		let checked = $('.js-privacy:checked');
		if (checked.length) {
			btns.removeAttr('disabled');
		} else {
			btns.attr('disabled', 'disabled');
		}
	}

	$('.js-privacy').on('change', function() {
		if ($(this).is(':checked')) {
			btns.removeAttr('disabled');
		} else {
			btns.attr('disabled', 'disabled');
		}
	});
});
