import $ from 'jquery';

const bodyHtml = $('body,html');
const body = $('body');

/* ======================================
body fix
====================================== */
let scrollPosi;

function bodyFix() {
	scrollPosi = $(window).scrollTop();

	body.addClass('is-fixed');
	body.css({ top: -scrollPosi });
}

function bodyFixReset() {
	body.removeClass('is-fixed');
	body.removeAttr('style');
	bodyHtml.scrollTop(scrollPosi);
}

$(function () {
	const btnMenu = $('.js-header-btn');
	const btnClose = $('.js-header-close');
	const header = $('.js-header');
	const menuItem = header.find('.js-header-mousemove');
	const content = $('.js-header-content');
	let contime

	if (btnMenu.length) {
		btnMenu.on('click', function () {
			header.addClass('is-active');

			if ($(window).outerWidth() < 768) {
				bodyFix();
			}
		});

		$(window).on('resize', function () {
			if ($(window).outerWidth() > 767) {
				bodyFixReset();
				header.removeClass('is-active');
			}
		});

		$(window).on('load resize', function () {
			const hWindow = $(window).innerHeight();

			content.css('height', hWindow);

			if ($(window).outerWidth() > 767) {
				if (contime) {
					clearTimeout(contime)
				}

				content.removeAttr('style');

			} else {
				contime = setTimeout(() => {
					content.css({
						'transition': '.3s ease',
					})
				}, 500);
			}
		});

		$(window).on('load scroll', function () {
			if ($(window).scrollTop() > 50) {
				header.addClass('is-scrolling')

			} else {
				header.removeClass('is-scrolling')
			}

			if ($(window).outerWidth() < 768) {
				header.removeClass('is-scrolling')

			} else {
				$(window).on('resize scroll', () => header.css('left', -$(window).scrollLeft()))
			}
		})

		btnClose.on('click', function () {
			header.removeClass('is-active');
			bodyFixReset();
		})

		if ($('.js-haccor').length) {
			$('.js-haccor').each(function () {
				$(this).on('click', function () {
					$(this).next().slideToggle()
					$(this).toggleClass('is-active')
				})
			})
		}

		menuItem.each(function () {
			$(this).on('mousemove', function () {
				$('.c-header__subcontent').css('top', $('.js-header').outerHeight());
				header.addClass('is-mousemove')
			})

			$(this).on('mouseleave', function () {
				header.removeClass('is-mousemove')
			})
		})
	}

});
