import $ from 'jquery';

$(function () {
	// $(window).on("load scroll resize orientationchange", function () {
	// 	let scpos = $(window).scrollTop();
	// 	let number = 300;
	// 	if (scpos >= number) {
	// 		$(".js-totop").removeClass("is-init");
	// 	} else {
	// 		$(".js-totop").addClass("is-init");
	// 	}
	// });


	$(window).on("load scroll resize orientationchange", function () {
		let htotop = $(".js-totop").innerHeight();
		let heightFooter
		let wH = $(window).outerHeight();
		let scpos = $(window).scrollTop() + wH;
		let spAdjust;
		let apperBorder = wH + 300;

		if ($(".c-footer__copyright").length) {
			heightFooter = $(".c-footer__copyright").offset().top + (htotop / 2);
		}

		if ($(window).outerWidth() > 767) {
			spAdjust = -50;
		} else {
			spAdjust = 0;
		}
		if (heightFooter + spAdjust < wH) {
			$(".js-totop").addClass("is-init");
			$(".js-totop").addClass("is-stop");
		} else {
			if (scpos >= apperBorder) {
				$(".js-totop").addClass("is-init");
				if (scpos >= heightFooter + spAdjust) {
					$(".js-totop").addClass("is-stop");
				} else {
					$(".js-totop").removeClass("is-stop");
				}
			} else {
				$(".js-totop").removeClass("is-stop");
				$(".js-totop").removeClass("is-init");
			}
		}
	});


	if ($('.js-totop').length) {
		$('.js-totop').on('click', function () {
			$('html,body').animate({ scrollTop: 0 }, 1000)
		})
	}

	$('.js-faccor').on('click', function () {
		if ($(window).outerWidth() < 768) {
			$(this).next().slideToggle()
			$(this).toggleClass('is-active')
		}
	})

});

$(window).on('load resize', function () {
	if ($(window).outerWidth() > 767) {
		$('.js-faccor').removeClass('is-active');
		$('.js-faccor-content').removeAttr('style');
	}
});
