import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

$(function () {
	const mvSlide = $('.js-mv-slide')
	const options = {
		loop: true,
		// autoHeight: true,
		observer: true,
		effect: "fade",
		speed: 3000,
		autoplay: true,
		noSwiping: false,
		allowTouchMove: false,
		autoplay: {
			delay: 4000,
		},
	}

	if (mvSlide.length) {
		const imgSlie = $('.c-mv4__imgslide')

		function handleHeightOfMv() {
			const textH = $('.c-mv4__text') && $('.c-mv4__text').innerHeight()
			const windowH = $(window).innerHeight()
			let margin = 40

			if ($(window).outerWidth() < 768) {
				margin = 93
			}

			const heightMv = windowH - textH - margin

			if (imgSlie.length) {
				imgSlie.each(function () {
					if ($(window).outerWidth() < 768) {
						$(this).css('height', heightMv)

					} else {
						$(this).removeAttr('style')
					}
				})
			}
		}

		handleHeightOfMv()

		$(window).on('resize', function () {
			handleHeightOfMv()
		})

		new Swiper(mvSlide[0], options);
	}
})
